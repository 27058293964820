import React from "react";
import styled from 'styled-components';
import { SiteContext } from 'context/site-context';
import { SectionGutters } from 'components/Section/Section';
import { type } from 'utils/type';
import { fontstack } from 'utils/fontstack';
import { media } from 'utils/mediaQuery';
import Layout from "../components/layout";
import SEO from "../components/seo";

const Wrapper = styled(SectionGutters)`
  position: relative;
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: flex-end;
`

const Half = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  ${media.small`
    width: ${props => props.shift ? `calc(50% - ${props.shift}px)` : '50%'};
  `}
`

const Line = styled.div`
  ${fontstack.default}
  ${type('body')}
  width: 100%;
  max-width: 390px;
`

const Statement = styled(Line)`
  color: rgb(var(--brand-grey));
`

const NotFoundPage = () => {
  const { InfoShiftValue } = React.useContext(SiteContext);

  return (
    <Layout omitFooter>
      <SEO title="404: Not found" />
      <Wrapper>
        <Half shift={InfoShiftValue}>
          <Line>404</Line>
          <Statement>Page Not Found</Statement>
        </Half>
      </Wrapper>
    </Layout>
  );
}

export default NotFoundPage;
